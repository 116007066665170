<template>
  <div class="wrapper">
    <header class="header">
      <div class="container">
        <div class="header-content">
          <div class="logo">
            <img :src="logo" alt="Miracle White" />
          </div>
          <nav class="nav">
            <a href="#">About Us</a>
            <a href="#">NEW</a>
            <a href="#">Product</a>
            <a href="#">Product Authentication</a>
            <a href="#">Contact Us</a>
          </nav>
        </div>
      </div>
    </header>

    <main>
      <section class="verify-section">
        <div class="container">
          <h1>VERIFICATION</h1>
          <p>We appreciate your support and participation in this effort to eliminate counterfeit products.</p>
          <div class="verify-result">
            <img :src="validIcon" alt="Valid" class="valid-icon" />
            <h2>THIS IS GENUINE PRODUCT</h2>
            <p>This product has been identified by the system as genuine.</p>
            <p>Please feel at ease to use the product.</p>
          </div>
        </div>
      </section>

      <section class="product-gallery">
        <div class="container">
          <h3>Miracle White packaging through the years</h3>
          <div class="product-slider">
            <div class="slider-container">
              <div class="slider-track" :style="transformStyle">
                <div v-for="(image, index) in products" :key="index" class="slide">
                  <img :src="image" :alt="'Product ' + (index + 1)" />
                </div>
              </div>
            </div>
            <button class="nav-button prev" @click="prev">&lt;</button>
            <button class="nav-button next" @click="next">&gt;</button>
          </div>
        </div>
      </section>

      <div class="button-section">
        <a href="#" class="cta-button">Our products</a>
      </div>
    </main>

    <footer>
      <div class="container">
        <p>© Copyright 2012 - 2021 | All Rights Reserved by Hess & Co.</p>
      </div>
    </footer>
  </div>
</template>

<script>
import logo from '@/assets/MW-logo.png'
import validIcon from '@/assets/valid.png'
import product1 from '@/assets/Product-1.jpg'
import product2 from '@/assets/Product-2.jpg'
import product3 from '@/assets/Product-3.jpg'
import product4 from '@/assets/Product-4.jpg'
import product5 from '@/assets/Product-5.jpg'
import product6 from '@/assets/Product-6.jpg'

export default {
  name: 'App',
  data() {
    return {
      logo,
      validIcon,
      products: [product1, product2, product3, product4, product5, product6],
      currentIndex: 0,
      autoPlayInterval: null
    }
  },
  computed: {
    transformStyle() {
      return {
        transform: `translateX(-${this.currentIndex * 25}%)`
      }
    }
  },
  methods: {
    next() {
      if (this.currentIndex < this.products.length - 4) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    },
    prev() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    startAutoPlay() {
      this.autoPlayInterval = setInterval(this.next, 5000);
    },
    stopAutoPlay() {
      if (this.autoPlayInterval) {
        clearInterval(this.autoPlayInterval);
      }
    }
  },
  mounted() {
    this.startAutoPlay();
  },
  beforeUnmount() {
    this.stopAutoPlay();
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  padding: 20px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo img {
  height: 60px;
}

.nav {
  display: flex;
  gap: 30px;
}

.nav a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
}

.verify-section {
  text-align: center;
  padding: 60px 0;
  background: #f5f5f5;
}

.verify-section h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.verify-result {
  margin-top: 40px;
}

.valid-icon {
  width: 100px;
  margin-bottom: 20px;
}

.verify-result h2 {
  margin-bottom: 15px;
}

.product-gallery {
  padding: 60px 0;
}

.product-gallery h3 {
  text-align: center;
  margin-bottom: 40px;
}

.product-slider {
  position: relative;
  padding: 0 40px;
}

.slider-container {
  overflow: hidden;
}

.slider-track {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  flex: 0 0 25%;
  padding: 0 10px;
  min-width: 25%;
}

.slide img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  aspect-ratio: 16/9;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0,0,0,0.5);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev { left: 0; }
.next { right: 0; }

.button-section {
  text-align: center;
  padding: 40px 0;
}

.cta-button {
  display: inline-block;
  padding: 12px 30px;
  background: #333;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 18px;
}

footer {
  margin-top: auto;
  background: #333;
  color: white;
  padding: 20px 0;
  text-align: center;
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    gap: 20px;
  }
  
  .nav {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .slide {
    flex: 0 0 100%;
    min-width: 100%;
  }
}
</style>